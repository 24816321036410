const isDev = process.env.NODE_ENV === 'development';

const ServerEnv = {
  isDev,
  aws: {
    accessKeyId:
      process.env.AWS_ACCESS_KEY_ID_PROJECT || process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey:
      process.env.AWS_SECRET_ACCESS_KEY_PROJECT ||
      process.env.AWS_SECRET_ACCESS_KEY,
    region:
      process.env.AWS_REGION_PROJECT || process.env.AWS_REGION || 'eu-central-1'
  },
  domain:
    process.env.APP_DOMAIN || process.env.NEXT_PUBLIC_VERCEL_URL || 'localhost'
};

export default ServerEnv;
