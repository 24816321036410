import NextNprogress from 'nextjs-progressbar';
import React from 'react';
import { theme } from 'twin.macro';
const PageLoader = () => {
  return (
    <NextNprogress
      color={theme`colors.primary.300`}
      startPosition={0.3}
      stopDelayMs={200}
      height={3}
    />
  );
};

export default PageLoader;
