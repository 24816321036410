import {
  accessToken,
  getEndpoints,
  repositoryName
} from '@leuven2030/framework/Prismic/config';
import Prismic from '@prismicio/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink, HttpLink, HttpOptions } from '@apollo/client';
import removeWhiteSpace from '@leuven2030/framework/Prismic/removeWhiteSpace';

export type PrismicLinkOptions = {
  httpOptions?: HttpOptions;
  repository?: string;
};
function PrismicApolloLink(options: PrismicLinkOptions = {}) {
  const { httpOptions, repository } = options;
  const { apiEndpoint, graphqlEndpoint } = getEndpoints(
    repository || repositoryName
  );
  const prismicClient = Prismic.client(apiEndpoint, { accessToken });

  const prismicLink = setContext((request, previousContext) => {
    //console.log('get api ref previousContext', previousContext);
    return prismicClient.getApi().then((api) => ({
      headers: {
        'Prismic-ref': api.masterRef.ref,
        ...previousContext.headers,
        ...(accessToken ? { Authorization: `Token ${accessToken}` } : {})
      }
    }));
  });

  const httpLink = new HttpLink({
    uri: graphqlEndpoint,
    useGETForQueries: true,
    fetch: (url, options) => {
      const trimmed = removeWhiteSpace(url);
      return fetch(trimmed, options);
    },
    ...httpOptions
  });

  return ApolloLink.from([prismicLink, httpLink]);
}

export default PrismicApolloLink;
