import urlJoin from 'url-join';
import ServerEnv from '@leuven2030/framework/Server/ServerEnv';
import ServerPort from '@leuven2030/framework/Server/ServerPort';

function getEndpoint() {
  if (process.env.GRAPHQL_ENDPOINT) {
    return process.env.GRAPHQL_ENDPOINT;
  }
  if (process.env.API_ENDPOINT) {
    return urlJoin(process.env.API_ENDPOINT, 'api/graphql');
  }

  if (ServerEnv.isDev) {
    return `http://localhost:${ServerPort}/api/graphql`;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return urlJoin(
      `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
      'api/graphql'
    );
  }
}

const GraphqlEndpoint = getEndpoint();
if (!GraphqlEndpoint) {
  console.warn('Could not find the graphql endpoint');
}
export default GraphqlEndpoint;
