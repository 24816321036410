export const repositoryName = process.env.PRISMIC_REPOSITORY;
const endpoints = getEndpoints(repositoryName);
export const prismicEndpoint = endpoints.prismicEndpoint;
export const apiEndpoint = endpoints.apiEndpoint;
export const graphqlEndpoint = endpoints.graphqlEndpoint;
export const accessToken = '';

export function getEndpoints(repositoryName) {
  const prismicEndpoint = `https://${repositoryName}.prismic.io`;
  return {
    prismicEndpoint,
    apiEndpoint: prismicEndpoint + '/api/v2',
    graphqlEndpoint: prismicEndpoint + '/graphql'
  };
}
